<template>
    <div>
        <l-map :zoom="13" :center="[38.7169, -9.1399]">
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          ></l-tile-layer>
          <l-marker v-for="driver in drivers" :key="driver.id" :lat-lng="[driver.lat, driver.lng]">
          </l-marker>
        </l-map>
      </div>
    
</template>
  
<script>
import { db } from '../firebase';
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
export default {
    components: { LMap, LTileLayer, LMarker },
    data() {
        return {
            drivers: [],
            ws: null,
        };
    },
    mounted() {
    db.collection("vendors")
      .where("active", "==", true)
      .onSnapshot((snapshot) => {
        this.drivers = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      });
  },
};
</script>

<style>
#map { height: 500px; }
</style>
  